import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactSection from '../components/contactSection';

const Contact = () => {
  return (
    <Layout>
      <SEO title='Contant' />
      <ContactSection isContactPage={true} />
    </Layout>
  );
};

export default Contact;
